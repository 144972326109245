<template>
    <div ref="container">
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('payment_info')"
                        :actions="actions"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('payment_info')"
                              :actions="actions"
                              :isFilter="false"/>
            </template>
            <div v-if="data!=null && data.customer!=null">
                <div class="border px-5 pt-5 mb-5 rounded">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mb-3 ellipsis-1"><label
                            class="col-form-label mr-1 p-0">{{ $t('student_number') }}:</label>{{
                                data.customer.number
                                                                                               }}
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mb-3 ellipsis-1"><label
                            class="col-form-label mr-1 p-0">{{
                                $t('national_number')
                                                            }}:</label>{{ data.customer.national_id }}
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mb-3 ellipsis-1"><label
                            class="col-form-label mr-1 p-0">{{ $t('name_surname') }}:</label>{{ data.customer.name }}
                                                                                             {{ data.customer.surname }}
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mb-3 ellipsis-1"><label
                            class="col-form-label mr-1 p-0">{{ $t('faculty') }}:</label>{{ getLocaleText(data.customer, 'faculty_name') }}
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mb-3 ellipsis-1"><label
                            class="col-form-label mr-1 p-0">{{ $t('program') }}:</label>{{ getLocaleText(data.customer, 'program_name') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-5 d-flex" v-if="data!=null && data.customer!=null">
                <b-button variant="outline-primary mr-3" @click="onlinePayment"><span
                    class="d-flex"><i class="ri-add-line mr-2"></i>{{ $t('make_payment') | toUpperCase }}</span>
                </b-button>

                <b-button variant="outline-primary mr-3" @click="$router.push('/my-receipts');" v-if="data.customer.group=='YU' && checkPermission('receipt_student')"><span
                    class="d-flex"><i class="ri-add-line mr-2"></i>{{ $t('upload_bank_receipt') | toUpperCase }}</span>
                </b-button>
            </div>
            <b-tabs content-class="py-5 position-relative"
                    v-if="data!=null && data.periods!=null">
                <div class="tabs-bottom-line"></div>
                <b-tab v-for="period in data.periods" v-bind:key="period.code">
                    <template #title><span class="d-flex align-items-center"><i
                        class="ri-calendar-line top-plus-1 mr-2"></i>{{ period.academic_year + (period.semester_name ? (' - ' + this.getLocaleText(period, 'semester_name')) : '')
                                                                     }}</span></template>
                    <div v-for="plan in data.payment_plans[period.code]" v-bind:key="plan.id">
                        <h6 class="text-uppercase mb-4">{{ getLocaleText(plan.product, 'name') | toUpperCase }}</h6>
                        <b-table
                            bordered
                            striped
                            responsive
                            :items="[plan]"
                            :fields="getPriceFields(plan)"
                            class="mb-4 table-dropdown no-scrollbar border rounded">
                        </b-table>
                        <b-table
                            v-if="plan.payments.length>0"
                            bordered
                            striped
                            responsive
                            :items="plan.payments"
                            :fields="paymentFields"
                            show-empty
                            class="mb-5 table-dropdown no-scrollbar border rounded">
                            <template #cell(due_date)="row">
                                <a class="ri-arrow-right-s-line"
                                   v-if="row.item.parent_id!=null"></a>{{ row.item.due_date | dateFormat }}
                            </template>
                            <template #cell(amount)="row">
                                <span v-if="row.item.parent_id==null">{{
                                        row.item.amount | formatToCurrency(plan.currency)
                                                                      }}</span>
                            </template>
                            <template #cell(amount_paid)="row">
                                <span v-if="row.item.is_paid && !row.item.has_sub_payments">{{
                                        row.item.amount | formatToCurrency(plan.currency)
                                                                                            }}</span>
                            </template>
                            <template slot="bottom-row">
                                <td></td>
                                <td><b>
                                    {{ plan.payments_sum_amount | formatToCurrency(plan.currency) }}</b></td>
                                <td><b>{{ plan.paid | formatToCurrency(plan.currency) }}</b></td>
                                <td><b>{{ $t('balance') }}: {{ plan.balance | formatToCurrency(plan.currency) }}</b>
                                </td>
                            </template>
                        </b-table>
                        <b-table
                            v-if="plan.refunds.length>0"
                            bordered
                            striped
                            responsive
                            :items="plan.refunds"
                            :fields="refundFields"
                            show-empty
                            class="mb-5 table-dropdown no-scrollbar border rounded">
                        </b-table>
                    </div>
                </b-tab>
            </b-tabs>
            <CommonModal id="vinovModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('vinov_instructions').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <VinovInstructions v-if="data && data.customer && showVinov"
                                       :student_program_id="student_program_id">

                    </VinovInstructions>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    import AppLayout from '@/layouts/AppLayout';
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
    import CustomerService from '@/services/CustomerService';
    import Base from '@/plugins/Base';
    import VinovInstructions from "@/modules/vinovInstructions/page/StudentList.vue";

    export default {
        name: 'Payments',
        props: {},
        components: {
            VinovInstructions,
            AppLayout,
            Header,
            HeaderMobile
        },
        data() {
            return {
                student_program_id: this.$route.params.student_program_id,
                data: null,
                showVinov:false,
                actions:[],
                priceFields: [
                    {
                        key: 'price',
                        label: this.toUpperCase('with_installment'),
                        sortable: true,
                        formatter: this.formatToCurrency
                    },
                    {
                        key: 'cash_price',
                        label: this.toUpperCase('cash'),
                        sortable: true,
                        formatter: this.formatToCurrency
                    },
                    {
                        key: 'credits',
                        label: this.toUpperCase('sum_of_credits'),
                        sortable: false,
                        visible: false
                    },
                    {
                        key: 'due_date',
                        label: this.toUpperCase('due_date'),
                        sortable: true,
                        formatter: this.formatDate
                    }
                ],
                paymentFields: [
                    {
                        key: 'due_date',
                        label: this.toUpperCase('due_date'),
                        formatter: this.formatDate
                    },
                    {
                        key: 'amount',
                        label: this.toUpperCase('amount_to_be_paid'),
                        formatter: this.formatToCurrency
                    },
                    {
                        key: 'amount_paid',
                        label: this.toUpperCase('amount_paid'),
                        formatter: this.formatToCurrency
                    },
                    {
                        key: 'payment_date',
                        label: this.toUpperCase('payment_date'),
                        formatter: this.formatDate
                    },
                ],
                refundFields: [
                    {
                        key: 'refund_date',
                        label: this.toUpperCase('refund_date'),
                        formatter: this.formatDate
                    },
                    {
                        key: 'amount',
                        label: this.toUpperCase('refund_amount'),
                        formatter: this.formatToCurrency
                    },
                ]
            }
        },
        created() {
            this.loadData();
        },
        methods: {
            getPriceFields(plan) {
                let fields = this.priceFields;
                if (!plan.credits) {
                    fields = this.priceFields.filter(item => {
                        return item.key != 'credits';
                    });
                }
                return fields;
            },
            loadData() {
                if (typeof this.student_program_id == 'undefined') {
                    this.student_program_id = 0;
                }
                CustomerService.getMyPayments(this.student_program_id)
                               .then(this.dataCallback)
                               .catch(e => {
                                   this.showErrors(e);
                               })
            },
            dataCallback(response) {
                this.data = response.data.data;
                this.actions = [
                    {
                        text: this.$t('vinov_instructions'),
                        permission: 'vinovinstruction_student',
                        callback: () => {
                            this.showVinov = true;
                            this.$bvModal.show('vinovModal');
                        },
                        show: () => {
                            return this.data.customer.group == 'TR';
                        }
                    },
                ];
            },

            formatToCurrency(value, key, item) {
                return this.$options.filters.formatToCurrency(value, item.currency);
            },
            formatDate(value) {
                return this.$options.filters.dateFormat(value);
            },
            onlinePayment() {
                let data = {customer_id: this.data.customer.id, student_program_id: this.student_program_id};
                CustomerService.createEpaymentLogin(data)
                               .then(response => {
                                   let data = response.data.data;
                                   Base.LocalStorage.set('epayment_token', data.token);
                                   this.$store.commit('epayment/setEpaymentToken', 'Bearer ' + data.token);
                                   Base.LocalStorage.set('epayment_user', data.customer);
                                   this.$store.commit('epayment/setEpaymentUser', data.customer)
                                   this.$router.push('/epayment');
                               })
                               .catch(e => {
                                   this.showErrors(e);
                               })
            }
        }
    }
</script>
